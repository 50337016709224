import { Script } from "gatsby";
import React, { useMemo } from "react";

interface VidazooProps {
  vidazooId: string;
  utm?: string;
}

function Vidazoo({ vidazooId, utm = "" }: VidazooProps): React.JSX.Element {
  const vidazooScript = useMemo(
    () => (
      <>
        <Script
          async
          strategy="idle"
          id="variablePositionVidazooJS"
          src="https://static.vidazoo.com/basev/vwpt.js"
          type="text/javascript"
          data-widget-id={vidazooId}
          data-widget-position="vidazooContainer"
          data-ext-bi-utm_source={utm}
          data-on-widget-ad-impression="onVidazooWidgetAdImpressionCallback"
        />
      </>
    ),
    [vidazooId, utm]
  );

  return (
    <div id="vidazooContainer" className="vidazoo">
      <Script>
        {`function onVidazooWidgetAdImpressionCallback(_cpm, info) {
          const adserverCurrency = "EUR"; // conversion to non USD requires the prebid currency module
          const fallbackConversionRate = 0.92; // conversion rate to use should the prebid module not be available (please make sure that a fallback conversion rate is configured in prebid as well)
          try {
              let highestPreBid_partner = "video_unknown";
              switch (info.adType) {
                  case "ima":
                      highestPreBid_partner = "video_AdX";
                      break;
                  case "marketplace":
                      highestPreBid_partner = "video_Vidazoo";
                      break;
                  case "pre_bid":
                      highestPreBid_partner = "video_" + info.bidderCode;
                      break;
                  default:
                      break;
              }
              let netCPM = info.netCpm - info.adServingFee;
              if (netCPM > 0) {
                  if (adserverCurrency && adserverCurrency !== "USD") {
                      if (pbjs && pbjs.convertCurrency) {
                          netCPM = pbjs.convertCurrency(netCPM, "USD", adserverCurrency);
                      } else {
                          netCPM = netCPM * fallbackConversionRate;
                      }
                  }
              }
              const data = {
                  source: "video",
                  slotId: "vidazoo_video",
                  adUnitPath: "vidazoo_video",
                  highestPreBid: netCPM,
                  highestPreBid_partner,
                  mediaType: "vidazoo_video",
                  creative_width: info.width,
                  creative_height: info.height,
                  preBidWon: true,
                  timeToRespond: null,
              };
              window.assertive.logCustomImpression(data);
          } catch (error) {
              console.error("Couldn't log custom impression", error);
          }
        }`}
      </Script>
      {vidazooScript}
    </div>
  );
}

export default Vidazoo;
